@import "assets/styles/vars.module.scss";

.section {
  background-color: $MAIN_BLACK;
}

.content {
  padding: 120px 0;
}

.title_h2__double {
  margin-bottom: 80px;
}

.filesList {
  display: flex;
  gap: 60px;
  overflow-x: auto;
}
.filesItem {
  position: relative;
  z-index: 10;
  height: 290px;
  //max-height: 100vh;
  flex: 0 0 auto;

  & img {
    padding: 0 20px 20px 0;
    //height: 100vh;
    max-width: 100%;
    max-height: 100%;
    z-index: 150;
  }
  &::after {
    content: "";
    position: absolute;
    bottom: 0;
    right: 0;
    background-color: $MAIN_GREY;
    border: $BORDER_GREY;
    height: calc(100% - 20px);
    width: calc(100% - 20px);
    transition: border 0.3s;
    z-index: -1;
  }
  &:hover::after {
    border: $BORDER_YELLOW;
  }
}
