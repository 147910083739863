@import "assets/styles/vars.module.scss";

.card {
  position: relative;
  flex: 0 0 auto;
}
.content {
  position: relative;
  z-index: 100;
  padding: 0 50px 50px 0;

  @media (max-width: 450px) {
    padding: 0 30px 30px 0;
  }
}
.contentBottom {
  margin-top: 60px;
  display: flex;
  justify-content: space-between;
}
.contentTop {
  border: 1px solid white;
  height: 220px;
  width: 100%;
  cursor: pointer;
  color: white;

  transition: all 0.3s;

  &:hover {
    color: $ACCENT_YELLOW;
    border: $BORDER_YELLOW;
  }
}
.descr {
  height: 120px;
  width: 60%;
  border: $BORDER_YELLOW;
}
.bg {
  position: absolute;
  bottom: 0;
  right: 0;
  border: 1px solid white;
  width: 90%;
  height: 90%;
}
.link {
  display: flex;
  justify-content: center;
  align-items: center;

  width: 100%;
  height: 100%;
}
