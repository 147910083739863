$MAIN_BLACK: #000200;
$MAIN_GREY: #252525;
$ACCENT_YELLOW: #ffc25b;

$BORDER_GREY: 1px solid #252525;
$BORDER_BLACK: 1px solid #000200;
$BORDER_YELLOW: 1px solid #ffc25b;

$PADDING-SEC: 100px 0;
$PADDING-SEC-EXTRA: 150px;
$MARGIN-TITLE: 80px;
$MARGIN-TITLE-MINI: 18px;

@mixin scrollbarDark {
  &::-webkit-scrollbar {
    height: 20px;
  }
  &::-webkit-scrollbar-track {
    background-color: $MAIN_GREY;
  }
  &::-webkit-scrollbar-thumb {
    background-color: rgba(0, 0, 0, 0.247);
    border: 8px solid transparent;
    border-radius: 10px;
    background-clip: content-box;
  }
}
@mixin scrollbarGrey {
  &::-webkit-scrollbar {
    height: 20px;
  }
  &::-webkit-scrollbar-track {
    background-color: rgba(0, 0, 0, 0);
  }
  &::-webkit-scrollbar-thumb {
    background-color: $MAIN_GREY;
    border: 8px solid transparent;
    border-radius: 10px;
    background-clip: content-box;
  }
}
@mixin scrollbarLight {
  &::-webkit-scrollbar {
    height: 20px;
  }
  &::-webkit-scrollbar-track {
    background-color: rgba(0, 0, 0, 0);
  }
  &::-webkit-scrollbar-thumb {
    background-color: white;
    border: 10px solid transparent;
    border-radius: 10px;
    background-clip: content-box;
  }
}

.container {
  max-width: 1145px;
  margin: 0 auto;
  padding: 0 15px;
  height: 100%;
  @media (max-width: 1200px) {
    padding: 0 30px;
  }
}

.pageTitle {
  font-size: 90px;
  font-weight: 800;
  letter-spacing: 8px;
  color: transparent;
  font-family: "Golos Text", sans-serif;
  -webkit-text-stroke: 1px $ACCENT_YELLOW;
  text-transform: uppercase;
  margin-bottom: 100px;

  @media (max-width: 800px) {
    font-size: 80px;
    word-break: break-all;
  }
}

.title_h2 {
  color: white;
  font-size: 40px;
  font-weight: 400;
  line-height: 1;

  @media (max-width: 1200px) {
    font-size: 34px;
  }
}
.title_h2__double {
  h2 {
    color: $ACCENT_YELLOW;
    font-size: 40px;
    font-weight: 400;
    line-height: 1;
  }
  p {
    margin-bottom: 10px;
    font-weight: 400;
    font-size: 20px;
    color: white;
    text-transform: uppercase;
    line-height: 1.3;
  }

  @media (max-width: 1200px) {
    h2 {
      font-size: 34px;
    }
    p {
      font-size: 16px;
      max-width: 80%;
    }
  }
}
.title_h3 {
  color: white;
  font-size: 25px;
  font-weight: 500;

  @media (max-width: 1200px) {
    font-size: 22px;
  }
}
.title_h4 {
  color: white;
  font-size: 18px;
  font-weight: 500;

  /*@media (max-width: 680px) {
    font-size: 16px;
  }*/
}

.text {
  line-height: 1.25;
  letter-spacing: 0.8px;
  font-size: 16px;

  /*@media (max-width: 680px) {
    font-size: 14px;
  }*/
}

.deco {
  position: relative;
  top: -10.8vw;

  .lineWrecked {
    position: absolute;
    top: -40px;
  }
  .bgGrey {
    position: absolute;
    top: 0;
  }
  .lineStraight {
    position: absolute;
    top: 20px;
  }
}

.logo {
  display: flex;
  align-items: center;
  gap: 10px;
  text-transform: uppercase;
  font-size: 12px;
  max-width: 170px;
  & img {
    height: 35px;
  }
  @media (max-width: 1200px) {
    font-size: 11px;
    & img {
      height: 30px;
    }
  }
}

.btn {
  background-color: $MAIN_BLACK;
  min-width: 285px;
  font-size: 20px;
  padding: 30px 35px;
  display: flex;
  gap: 57px;
  align-items: center;
  transition: background-color 0.3s;

  & img {
    width: 35px;
    height: 35px;
  }
  &:hover {
    background-color: $MAIN_GREY;
  }

  @media (max-width: 650px) {
    min-width: 260px;
    padding: 25px 30px;
    font-size: 16px;
    gap: 40px;
    & img {
      width: 25px;
      height: 25px;
    }
  }
  @media (max-width: 500px) {
    min-width: 0;
    padding: 25px 30px;
    font-size: 16px;
    gap: 40px;
    & img {
      width: 25px;
      height: 25px;
    }
  }
  @media (max-width: 420px) {
    font-size: 14px;
    gap: 30px;
    padding: 20px 25px;
    & img {
      width: 20px;
      height: 20px;
    }
  }
}

.sliderBtns {
  display: flex;
  gap: 13px;
  flex-direction: column;
}

.btnCircle {
  height: 12px;
  width: 12px;
  border-radius: 50%;
  border: $BORDER_YELLOW;
  &__active {
    background-color: $ACCENT_YELLOW;
  }
}
