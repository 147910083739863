@import "assets/styles/vars.module.scss";

.content {
  min-height: 1000px;
  padding: 100px 0 120px 0;
}

.cards {
  display: flex;
  justify-content: space-between;
  gap: 30px;
  margin-bottom: 80px;
  overflow-x: auto;

  @include scrollbarGrey;

  & > article {
    min-width: 230px;
    cursor: pointer;
  }
}

.serviceInfo {
  display: flex;
  justify-content: space-between;

  @media (max-width: 700px) {
    flex-direction: column;
    gap: 80px;
  }
}
.text {
  flex: 0 0 45%;
  & p:not(:last-of-type) {
    margin-bottom: 35px;
  }
}
.serviceImg {
  flex: 0 0 45%;
  display: flex;
  justify-content: center;
  align-items: center;
  position: relative;

  & img {
    position: relative;
    top: 50px;
    height: 200px;
    object-fit: contain;
  }
  &::after {
    content: "";
    width: 300px;
    height: 160px;
    border: $BORDER_YELLOW;
    position: absolute;
    z-index: -1;
    top: 20px;
    left: 100px;
  }

  @media (max-width: 550px) {
    &::after {
      top: 20px;
      left: 50%;
    }
  }
}
