@import "assets/styles/vars.module.scss";

.content {
  padding: 100px 0 120px 0;
}

.aboutContent {
  display: flex;
  justify-content: space-between;

  @media (max-width: 800px) {
    flex-direction: column;
    gap: 80px;
  }
}
.aboutInfo {
  flex: 0 0 45%;

  & p:not(:last-of-type) {
    margin-bottom: 35px;
  }
}

.aboutStat {
  display: grid;
  grid-template-columns: 1fr 1fr;
  gap: 50px;

  @media (max-width: 800px) {
    grid-template-columns: 200px 200px;
    row-gap: 70px;
  }
  @media (max-width: 500px) {
    grid-template-columns: 1fr 1fr;
  }
}
.statItem {
  max-width: 160px;
  .num {
    font-size: 64px;
    color: $ACCENT_YELLOW;
  }
  .descr {
    margin-top: 10px;
  }

  @media (max-width: 400px) {
    & .num {
      font-size: 40px;
    }
  }
}

.pageTitle {
  word-break: keep-all;
}
