@import "assets/styles/vars.module.scss";

.content {
  display: flex;
  align-items: center;
  gap: 50px;

  @media (max-width: 1000px) {
    justify-content: center;
  }
  @media (max-width: 450px) {
    flex-direction: column;
    align-items: center;
    text-align: center;
    gap: 40px;
  }
}

.graphics {
  background-color: $MAIN_BLACK;
  min-width: 150px;
  min-height: 150px;
  border-radius: 50%;
  position: relative;
  img {
    position: absolute;
    bottom: -20px;
    right: -10px;
    width: 110px;
  }

  @media (max-width: 1200px) {
    min-width: 120px;
    min-height: 120px;
    img {
      width: 80px;
    }
  }
}

.descr {
  max-width: 230px;

  @media (max-width: 450px) {
    max-width: 70%;
  }
}
.title_h3 {
  margin-bottom: 20px;

  @media (max-width: 450px) {
    margin-bottom: 15px;
  }
}

.contentLeft {
  column-gap: 30px;
  & .graphics img {
    left: -15px;
  }

  @media (max-width: 450px) {
    & .descr {
      order: 2;
    }
    & .graphics {
      order: 1;
    }
  }
}
