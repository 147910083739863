@import "assets/styles/vars.module.scss";

.section {
}

.content {
  padding: 160px 0 360px 0;

  @media (max-width: 800px) {
    padding: 120px 0 200px 0;
  }
}

.title_h2 {
  margin-bottom: 80px;
  @media (max-width: 800px) {
    margin-bottom: 60px;
  }
}

.cards {
  display: flex;
  justify-content: space-between;
  flex-wrap: wrap;
  gap: 30px;

  & > article {
    flex: 1 1 250px;
  }
}
