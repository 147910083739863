@import "assets/styles/vars.module.scss";

.content {
  padding: 37px 25px 27px 25px;
  position: relative;

  background-color: $MAIN_GREY;
  border: $BORDER_GREY;
  transition: border 0.3s;

  display: flex;
  flex-direction: column;
  justify-content: space-between;

  &:hover {
    border: $BORDER_YELLOW;
  }
  /*@media (max-width: 680px) {
    flex-direction: row;
    justify-content: start;
    align-items: center;
  }*/
}

.top {
  flex: 0 0 40px;
  margin-bottom: 60px;

  /* @media (max-width: 680px) {
    margin-bottom: 0;
    margin-right: 30px;
    img {
      height: 40px;
    }
  }*/
}

/*.title_h4 {
  max-width: 200px;
}*/
.text {
  margin-top: 25px;
  /* @media (max-width: 680px) {
    display: none;
  }*/
}
