@import "assets/styles/vars.module.scss";

.content {
  display: flex;
  justify-content: space-between;
  align-items: center;
  padding: 30px 0;
}

.menu {
  display: flex;
  gap: 60px;

  .link {
    transition: color 0.3s;
  }
  .link:hover {
    color: $ACCENT_YELLOW;
  }
  @media (max-width: 900px) {
    display: none;
  }
}

.burgerBtn {
  display: none;
  position: relative;
  z-index: 100;
  width: 30px;
  height: 30px;
  transition: all 0.3s;
  z-index: 210;
  span {
    height: 1px;
    width: 30px;
    background-color: $ACCENT_YELLOW;
  }
  @media (max-width: 900px) {
    display: flex;
    flex-direction: column;
    justify-content: center;
    gap: 6px;
  }
}
.burgerBtnActive {
  gap: 0px;

  span {
    position: absolute;
    transition: all 0.3s;
  }
  & > :nth-child(1) {
    display: none;
  }
  & > :nth-child(2) {
    transform: rotate(-45deg);
  }
  & > :nth-child(3) {
    transform: rotate(45deg);
  }
}
.mobileMenu {
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background: $MAIN_BLACK;
  z-index: 200;
  display: flex;
  justify-content: center;
  flex-direction: column;
  align-items: center;
  gap: 60px;
  font-size: 1.2em;
  animation: mobileMenuShow 0.3s forwards;

  & body {
    overflow: hidden;
  }
  & li {
    position: relative;
  }
  & .link {
    position: relative;
    animation: menuItemShow 0.6s;
  }
  &::before {
    content: "";
    position: absolute;
    bottom: -50%;
    left: -50%;
    height: 1px;
    width: 100vh;
    background-color: $ACCENT_YELLOW;
    opacity: 0.6;
    animation: mobileMenuDeco 25s;
    transform: rotate(45deg);
  }
}
.mobileMenuClose {
  & .link {
    animation: menuItemHide 0.5s;
  }
  animation: mobileMenuHide 0.6s;
}

@keyframes mobileMenuShow {
  from {
    opacity: 0;
  }
  to {
    opacity: 1;
  }
}
@keyframes menuItemShow {
  from {
    opacity: 0;
    top: -30px;
  }
  to {
    opacity: 1;
    top: 0;
  }
}

@keyframes mobileMenuHide {
  from {
    opacity: 1;
  }
  to {
    opacity: 0;
  }
}
@keyframes menuItemHide {
  from {
    opacity: 1;
    top: 0;
  }
  to {
    opacity: 0;
    top: -30px;
  }
}

@keyframes mobileMenuDeco {
  from {
    bottom: -50%;
  }
  to {
    bottom: 150%;
  }
}
