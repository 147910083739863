@import "assets/styles/vars.module.scss";

.section {
  background-color: $MAIN_GREY;
}

.content {
  padding: 120px 0 160px 0;

  @media (max-width: 700px) {
    padding: 120px 0;
  }
}

.slider {
  display: flex;
  gap: 70px;
  align-items: center;

  /* @media (max-width: 1200px) {
    flex-direction: column;
    align-items: start;
  }
  @media (max-width: 1000px) {
    flex-direction: row;
    align-items: center;
  }
  @media (max-width: 700px) {
    flex-direction: column;
    align-items: start;
  }*/
}
.sliderCards {
  display: flex;
  gap: 70px;
  overflow-x: auto;

  @include scrollbarDark;

  & > article {
    flex: 0 0 440px;
    max-width: 100%;
  }

  @media (min-width: 1200px) {
    & > article {
      display: none;
    }
    & > article:nth-child(1) {
      display: flex;
    }
    & > article:nth-child(2) {
      display: flex;
    }
  }
}

.title_h2__double {
  margin-bottom: 80px;
}

.nextBtn {
  cursor: pointer;
  position: relative;
  width: 80px;
  height: 50px;

  & div {
    position: relative;
    top: 20px;
    width: 90px;
    height: 1px;
    background-color: $ACCENT_YELLOW;
    box-shadow: 0 3px 5px rgba(0, 0, 0, 0.2);
    left: 0;
    display: block;
  }
  & div::after {
    content: "";
    position: absolute;
    width: 30px;
    height: 1px;
    top: -10.5px;
    right: -5px;
    background-color: $ACCENT_YELLOW;
    transform: rotate(45deg);
  }
  & div::before {
    content: "";
    position: absolute;
    width: 30px;
    height: 1px;
    top: 10.5px;
    right: -5px;
    background-color: $ACCENT_YELLOW;
    box-shadow: 0 3px 5px rgba(0, 0, 0, 0.2);
    transform: rotate(-45deg);
  }

  @media (max-width: 1200px) {
    display: none;
  }
}
