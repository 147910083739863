@import "assets/styles/vars.module.scss";

.section {
  background-color: $MAIN_GREY;
}

.content {
  padding: 100px 0 160px 0;

  @media (max-width: 450px) {
    padding: 100px 0 120px 0;
  }
}

.title_h2__double {
  margin-bottom: 120px;

  @media (max-width: 450px) {
    margin-bottom: 80px;
    text-align: center;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
  }
}

.advantages {
  max-width: 90%;
  margin: 0 auto;
  display: grid;
  grid-template-columns: 1fr 1fr;
  column-gap: 50px;
  row-gap: 80px;

  @media (max-width: 1000px) {
    grid-template-columns: 1fr;
    max-width: 100%;
    row-gap: 100px;
    & > :nth-child(1) {
      order: 1;
    }
    & > :nth-child(2) {
      order: 3;
    }
    & > :nth-child(3) {
      order: 2;
    }
    & > :nth-child(4) {
      order: 4;
    }
  }
  @media (max-width: 450px) {
    row-gap: 70px;
  }
}
