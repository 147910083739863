@import "assets/styles/vars.module.scss";

.content {
  padding: 150px 0 60px 0;
  display: flex;
  flex-direction: column;
}

.logo {
  font-size: 16px;
  max-width: 200px;
  & img {
    min-height: 50px;
  }
}

.footerTop {
  margin-bottom: 45px;

  @media (max-width: 900px) {
    margin-bottom: 75px;
  }
}
.footerCenter {
  margin-bottom: 150px;
  display: flex;
  justify-content: space-between;
  gap: 20px;

  @media (max-width: 900px) {
    flex-wrap: wrap-reverse;
    row-gap: 90px;
  }
}
.footerBottom {
  border-top: $BORDER_YELLOW;
  padding-top: 30px;
  font-size: 13px;
}
.column_1 {
  flex: 0 0 33%;
  @media (max-width: 900px) {
    flex: 0 0 100%;
  }
}
.column_2 {
  @media (max-width: 900px) {
    flex: 1 0 45%;
  }
  @media (max-width: 650px) {
    flex: 0 0 100%;
  }
}
.column_3 {
  @media (max-width: 900px) {
    flex: 0 0 30%;
  }
}

.text {
  margin-bottom: 70px;
}
.social {
  display: flex;
  gap: 40px;

  @media (max-width: 800px) {
    gap: 50px;
    & img {
      max-height: 25px;
    }
  }
}

.title_h4 {
  margin-bottom: 30px;
}
.cities {
  display: flex;
  gap: 50px;
}
.citiesUl {
  li:not(:last-of-type) {
    margin-bottom: 18px;
  }
}

.menu {
  li:not(:last-of-type) {
    margin-bottom: 18px;
  }
  a:hover {
    color: $ACCENT_YELLOW;
  }
}
