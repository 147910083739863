@import "assets/styles/vars.module.scss";

.content {
  padding: 100px 0 160px 0;
}

.title_h2 {
  margin-bottom: 100px;
}
.cards {
  display: flex;
  justify-content: space-between;
  margin-bottom: 80px;
  gap: 30px;

  @media (max-width: 1200px) {
    display: grid;
    grid-template-columns: 1fr 0.4fr 1fr 1fr 0.4fr;
    grid-template-rows: auto auto;
    gap: 20px;

    & :nth-child(1) {
      grid-column: 1 / 3;
      grid-row: 1 / 2;
    }
    & :nth-child(2) {
      grid-column: 1 / 2;
      grid-row: 2 / 3;
    }
    & :nth-child(3) {
      grid-column: 3 / 4;
      grid-row: 1 / 3;
    }
    & :nth-child(4) {
      grid-column: 4 / 5;
      grid-row: 1 / 2;
    }
    & :nth-child(5) {
      grid-column: 4 / 6;
      grid-row: 2 / 3;
    }
  }

  @media (max-width: 550px) {
    display: flex;
    flex-wrap: wrap;

    & .cardItem {
      flex: 1 1 120px;
    }
  }
}
.cardItem {
  border: $BORDER_YELLOW;
  width: 190px;
  height: 190px;
  display: flex;
  justify-content: center;
  align-items: center;
  transition: background-color 0.3s;
  position: relative;
  overflow: hidden;

  & img {
    max-width: 100px;
    object-fit: contain;
  }
  &:hover {
    cursor: pointer;
  }
  &::after {
    content: "";
    position: absolute;
    width: 65%;
    height: 100%;
    top: 0;
    left: -70px;
    background-color: $ACCENT_YELLOW;
    z-index: -1;
    opacity: 0;
    transition: all 0.3s;
  }
  &:hover::after {
    content: "";
    opacity: 1;
    left: 0;
  }

  @media (max-width: 1200px) {
    width: auto;
    height: auto;
    & img {
      max-height: 60%;
      max-width: 60%;
    }
  }
}

.equipmentInfo {
  display: flex;
  justify-content: space-between;

  .descr {
    flex: 0 1 50%;
  }
  .producers {
    flex: 0 0 40%;
  }
  .producersLogos {
    display: flex;
    flex-wrap: wrap;
    gap: 20px;
    & img {
      max-height: 60px;
      object-fit: contain;
    }
  }
  .title_h3 {
    margin-bottom: 30px;
  }
  & p:not(:last-of-type) {
    margin-bottom: 20px;
  }

  @media (max-width: 800px) {
    flex-direction: column;
    gap: 80px;
  }
}
