@import "assets/styles/vars.module.scss";

@import "assets/styles/vars.module.scss";

.card {
  position: relative;
  flex: 1 1 450px;
  max-width: 80%;

  @media (max-width: 550px) {
    max-width: 100%;
  }
}
.content {
  position: relative;
  z-index: 100;
  border: $BORDER_YELLOW;
  padding: 0 50px 50px 50px;

  @media (max-width: 600px) {
    padding: 0 30px 30px 30px;
  }
}
.contentTop {
  border: 1px solid transparent;
  cursor: pointer;
  transition: border 0.3s;
  margin-top: -60px;
  margin-left: -110px;
  & img {
    width: 100%;
    height: 300px;
    object-fit: cover;
  }
  &:hover {
    border: $BORDER_YELLOW;
  }

  @media (max-width: 600px) {
    margin-left: -90px;
  }
}
.contentBottom {
  margin-top: 60px;
  display: flex;
  justify-content: space-between;

  @media (max-width: 600px) {
    margin-top: 40px;
    margin-left: -90px;
    background-color: $MAIN_BLACK;
    border-top: 20px solid $MAIN_BLACK;
    border-bottom: 20px solid $MAIN_BLACK;
  }
}

.title_h3 {
  color: $ACCENT_YELLOW;
  margin-bottom: 28px;
}

.text {
  max-width: 70%;

  & p:not(:last-of-type) {
    margin-bottom: 25px;
  }

  @media (max-width: 500px) {
    max-width: 80%;
  }
}
