@import "assets/styles/vars.module.scss";

.content {
  padding: 100px 0 360px 0;

  @media (max-width: 650px) {
    padding: 100px 0 260px 0;
  }
}

.contactsCard {
  padding: 75px 65px;
  background-color: $ACCENT_YELLOW;
  display: flex;
  justify-content: space-between;
  column-gap: 20px;

  @media (max-width: 1000px) {
    flex-direction: column-reverse;
    row-gap: 100px;
  }
  @media (max-width: 550px) {
    padding: 45px 35px;
  }
  @media (max-width: 400px) {
    padding: 35px 25px;
  }
}
.contactsForm {
  flex: 0 0 30%;
  & button {
    margin-top: 70px;
    width: 100%;
    background-color: $MAIN_BLACK;
    padding: 22px;
    font-size: 16px;
    color: white;
    display: flex;
    justify-content: center;
    align-items: center;
    font-weight: 200;
    transition: background-color 0.3s;
  }
  & button:hover {
    background-color: $MAIN_GREY;
  }
  & input {
    width: 100%;
    color: $MAIN_BLACK;
    font-weight: 200;
    font-size: 16px;
    padding-bottom: 15px;
    border-bottom: $BORDER_GREY;
  }
  & input::placeholder {
    color: $MAIN_GREY;
  }
  & input:focus {
    border-bottom: $BORDER_BLACK;
  }
  & input:focus::placeholder {
    color: $MAIN_BLACK;
  }
  & input:not(:last-of-type) {
    margin-bottom: 55px;
  }
}

.contactsSocial {
  display: grid;
  grid-template-columns: 1fr 1fr;
  grid-template-rows: auto;
  gap: 20px;

  & > :first-child {
    grid-column: 1 / 2;
    grid-row: 1 / 4;
    background-image: url("../../../assets/icons/socialThin/telegram.svg");
    background-size: 300px;
    background-repeat: no-repeat;
    background-position: bottom -40px left -80px;
  }
  & > :nth-child(2) {
    grid-column: 2 / 3;
    grid-row: 1 / 3;
    display: flex;
    flex-direction: column;
    justify-content: flex-end;
    background-image: url("../../../assets/icons/socialThin/whatsapp.svg");
    background-size: 300px;
    background-repeat: no-repeat;
    background-position: top -150px right -120px;
  }
  & > :nth-child(3) {
    grid-column: 2 / 3;
    grid-row: 3 / 4;
    display: flex;
    flex-direction: column;
    justify-content: flex-end;
  }

  @media (max-width: 700px) {
    display: flex;
    flex-direction: column;

    & > :first-child {
      display: flex;
      flex-direction: column;
      justify-content: flex-end;
      align-items: flex-end;
      background-size: 300px;
      background-position: top -30px left -120px;
    }
    & > :nth-child(2) {
      align-items: end;
      background-size: 350px;
      background-position: top -90px left -140px;
    }
    & > :nth-child(3) {
      align-items: flex-end;
    }
  }
}
.socialItem {
  background-color: $MAIN_BLACK;
  padding: 35px;
  display: block;

  & .socialType {
    font-weight: 500;
    font-size: 13px;
    margin-bottom: 25px;
  }
  & .socialId {
    font-size: 20px;
    transition: color 0.3s;
    font-weight: 100;
  }
  &:hover .socialId {
    color: $ACCENT_YELLOW;
  }
  &:hover {
    cursor: pointer;
  }

  @media (max-width: 750px) {
    & .socialId {
      font-size: 17px;
    }
  }
  @media (max-width: 550px) {
    & .socialId {
      font-size: 15px;
    }
  }
}
