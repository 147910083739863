@import "assets/styles/vars.module.scss";

.card {
  position: relative;

  @media (max-width: 1100px) {
    max-width: 80%;
  }
  @media (max-width: 550px) {
    max-width: 100%;
  }
}
.content {
  position: relative;
  z-index: 100;
  border: $BORDER_YELLOW;
  padding: 50px;
  display: flex;

  @media (max-width: 1100px) {
    flex-direction: column;
  }
  @media (max-width: 600px) {
    padding: 30px;
  }
}
.contentLeft {
  flex: 0 0 60%;

  margin-top: -110px;
  margin-left: -110px;
  margin-bottom: -110px;

  display: grid;
  grid-template-columns: 1fr 1fr;
  grid-template-rows: auto auto;
  gap: 30px;

  & > :first-child {
    grid-column: 1 / 3;
    grid-row: 1 / 2;
  }
  & > :nth-child(2) {
    grid-column: 1 / 2;
    grid-row: 2 / 3;
  }
  & > :nth-child(3) {
    grid-column: 2 / 3;
    grid-row: 2 / 3;
  }
  & img {
    width: 100%;
    max-height: 300px;
    object-fit: cover;

    border: 1px solid transparent;
    transition: border 0.3s;
    cursor: pointer;
  }
  & img:hover {
    border: $BORDER_YELLOW;
  }

  @media (max-width: 1200px) {
    & img {
      max-height: none;
    }
  }
  @media (max-width: 1100px) {
    margin-bottom: 0;
    display: flex;

    & > :not(:first-child) {
      display: none;
    }
    & img {
      max-height: 300px;
    }
  }
  @media (max-width: 600px) {
    margin-left: -90px;
  }
}
.contentRight {
  display: flex;
  justify-content: space-between;

  @media (max-width: 1100px) {
    margin-top: 60px;
    display: flex;
    flex-direction: row-reverse;
  }
  @media (max-width: 600px) {
    margin-top: 40px;
    margin-left: -90px;
    background-color: $MAIN_BLACK;
    border-top: 20px solid $MAIN_BLACK;
    border-bottom: 20px solid $MAIN_BLACK;
  }
}

.bg {
  position: absolute;
  bottom: 0;
  right: 0;

  width: 90%;
  height: 90%;
}

.title_h3 {
  color: $ACCENT_YELLOW;
  margin-bottom: 40px;

  @media (max-width: 1100px) {
    margin-bottom: 28px;
  }
}

.text {
  max-width: 95%;
  & p:not(:last-of-type) {
    margin-bottom: 25px;
  }

  @media (max-width: 1100px) {
    max-width: 80%;
  }
}
.sliderBtns {
  margin-left: 50px;
}
.descr {
  margin-left: 65px;

  @media (max-width: 1100px) {
    margin-left: 0;
  }
}
