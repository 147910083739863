@import "assets/styles/vars.module.scss";

.content {
  padding: 120px 0 160px 0;
  position: relative;

  @media (max-width: 520px) {
    padding: 120px 0 120px 0;
  }
}

.title_h2 {
  margin-bottom: 80px;
}

.workScheme {
  display: flex;
  flex-direction: column;
  max-width: 40%;
  gap: 50px;

  @media (max-width: 1000px) {
    max-width: 60%;
  }
  @media (max-width: 750px) {
    max-width: 80%;
  }
  @media (max-width: 520px) {
    max-width: 95%;
    gap: 70px;
  }
}
.workItem {
  display: flex;
  gap: 30px;
  align-items: center;
}
.title_h4 {
  margin-bottom: 10px;
}

.graphics {
  background-color: $ACCENT_YELLOW;
  min-width: 75px;
  min-height: 75px;
  border-radius: 50%;
  position: relative;

  & span {
    color: $MAIN_BLACK;
    font-size: 90px;
    position: absolute;
    bottom: -30px;
    left: 15px;
  }
}

.decoIn {
  position: absolute;
  top: 200px;
  right: 0;
  width: 410px;
  height: 410px;
  border: $BORDER_YELLOW;
  opacity: 0.4;
}
.decoOut {
  position: absolute;
  top: 150px;
  right: -300px;
  width: 600px;
  height: 410px;
  border: $BORDER_YELLOW;
  opacity: 0.4;

  @media (max-width: 1000px) {
    top: 120px;
  }
  @media (max-width: 750px) {
    top: 80px;
  }
}
