@import "assets/styles/vars.module.scss";

.section {
  background-color: $MAIN_GREY;
}
.content {
  padding: 120px 0 300px 0;

  @media (max-width: 800px) {
    padding: 100px 0 200px 0;
  }
}

.title_h2__double {
  margin-bottom: 80px;
}

.solutionsSwitcher {
  display: flex;
  justify-content: space-between;

  @media (max-width: 1000px) {
    flex-direction: column;
  }
}
.solutionsMenu {
  flex: 0 0 30%;
  ul {
    display: flex;
    flex-direction: column;
    gap: 30px;
  }

  @media (max-width: 1000px) {
    margin-bottom: 35px;

    ul {
      flex-direction: row;
      overflow-x: auto;
      @include scrollbarLight;
    }
  }
}
.btn {
  background-color: transparent;
  border: 1px solid white;
  cursor: pointer;
  &:hover {
    background-color: $ACCENT_YELLOW;
    border: $BORDER_YELLOW;
    color: $MAIN_BLACK;
  }

  @media (max-width: 1000px) {
    white-space: nowrap;
    min-width: auto;
  }
}

.solutionsCard {
  flex: 0 0 60%;
  position: relative;

  .cardContent {
    position: relative;
    z-index: 100;
    padding: 0 50px 50px 0;
  }
  .cardContent::after {
    content: "";
    position: absolute;
    bottom: 0;
    right: 0;
    background-color: $MAIN_BLACK;
    width: calc(100% - 60px);
    height: calc(100% - 60px);
    z-index: -1;
  }
  .cardImg {
    & img {
      width: 100%;
      max-height: 200px;
      object-fit: cover;
    }
  }
  .cardInfo {
    display: flex;
    justify-content: space-between;
    margin-top: 60px;
  }
  .cardLeft {
    flex: 0 0 45%;
    display: flex;
    justify-content: space-between;
  }
  .cardRight {
    flex: 0 0 45%;
    border: $BORDER_YELLOW;
    padding: 40px;
    & > :not(:last-child) {
      margin-bottom: 20px;
    }
  }
  .purchaseLine {
    font-size: 14px;
    line-height: 1.5;
    & :last-child {
      color: $ACCENT_YELLOW;
      font-weight: 500;
      margin-top: 4px;
    }
  }
  .finalCost {
    color: $ACCENT_YELLOW;
    font-size: 23px;
    margin-top: 40px;
    font-weight: 500;
  }
  .title_h3 {
    color: $ACCENT_YELLOW;
    margin-bottom: 28px;
  }
  .text {
    display: flex;
    flex-direction: column;
    gap: 20px;
  }

  @media (max-width: 650px) {
    .cardInfo {
      flex-direction: column;
      gap: 40px;
    }
    .cardRight {
      max-width: 320px;
    }
    .cardContent {
      padding: 0 30px 30px 0;
    }
    .cardContent::after {
      width: calc(100% - 30px);
      height: calc(100% - 30px);
    }
  }
}
