@import "assets/styles/vars.module.scss";

.section {
  height: 90svh;
  max-height: 100%;
  position: relative;
  &::before {
    content: "";
    width: 70%;
    height: 100%;
    position: absolute;
    top: 0;
    right: 0;
    background-image: url("../../../assets/img/promo/promo.jpg");
    background-repeat: no-repeat;
    background-size: cover;
    background-position: top right;
  }
  &::after {
    content: "";
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    background-color: $MAIN_BLACK;
    opacity: 0.6;
  }
  @media (max-width: 1200px) {
    &::before {
      background-size: auto 120%;
      background-position: top right 70%;
    }
  }
  @media (max-width: 550px) {
    &::before {
      background-position: top right 55%;
    }
  }
}

.content {
  height: 100%;
  color: white;
  display: flex;
  flex-direction: column;
  justify-content: center;
  position: relative;
  z-index: 100;
}

.title {
  font-size: 120px;
  font-weight: 800;
  text-transform: uppercase;
  line-height: 1;
  letter-spacing: 12px;
  font-family: "Golos Text", sans-serif;
  -webkit-text-fill-color: transparent;
  -webkit-text-stroke: 1px $ACCENT_YELLOW;

  @media (max-width: 1050px) {
    font-size: 100px;
  }
  @media (max-width: 870px) {
    display: none;
  }
}
.titleMobile {
  display: none;
  @media (max-width: 870px) {
    max-width: 500px;
    font-size: 90px;
    display: inline;
  }
  @media (max-width: 480px) {
    font-size: 80px;
  }
  @media (max-width: 400px) {
    font-size: 60px;
  }
}

.subtitle {
  margin-top: 70px;
  font-size: 20px;
  max-width: 160px;
  line-height: 1.3;
  letter-spacing: 2px;
  color: white;
  text-transform: uppercase;
}
