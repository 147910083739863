@import "./reset.module.scss";
@import "./vars.module.scss";

@import url("https://fonts.googleapis.com/css2?family=Geologica:wght@100;200;300;400;700&display=swap");

@import url("https://fonts.googleapis.com/css2?family=Roboto:wght@900&display=swap");

@import url("https://fonts.googleapis.com/css2?family=Mulish:wght@900;1000&display=swap");

@import url("https://fonts.googleapis.com/css2?family=Golos+Text:wght@800;900&display=swap");

body {
  font-family: "Geologica", sans-serif;
  background-color: $MAIN_BLACK;

  color: white;
  font-weight: 200;
  font-size: 16px;
  line-height: 1.1;
  letter-spacing: 0.4px;
}

.wrapper {
  overflow: hidden;
}
